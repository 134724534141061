import React from "react";
import Index from "../components/newsletter";

const newsletter = () => {
  return (
    <>
      <Index />
    </>
  );
};

export default newsletter;
