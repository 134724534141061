import React from "react";
import Footer from "../home/footer/Footer";
import Navbar from "../home/navbar/Navbar";
import Newsletter from "./Newsletter";
import SEO from "../Seo";
import NewsletterHead from "./NewsletterHead";
import Testimonials from "./Testimonials";
import * as styles from "./newsLetter.module.css";

const Index = () => {
  return (
    <div className={styles.componentWrapper}>
      <SEO titleStub={`Newsletter`} urlStub={`/newsletter/`} />
      <Navbar />
      <NewsletterHead />
      <Newsletter />
      <Testimonials />
      <Footer />
    </div>
  );
};

export default Index;
