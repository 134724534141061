import React from "react";
import * as styles from "./newsLetter.module.css";

const NewsletterHead = () => {
  return (
    <div className={styles.mainSection}>
      <div className={styles.contentWrap}>
        <div className={styles.content}>
          <h1>
            <span>Money Making Engineer | Newsletter</span>
          </h1>
          <h3>
            I teach you to make more money without leaving your full-time jobs
          </h3>
        </div>
      </div>
    </div>
  );
};

export default NewsletterHead;
